import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";

interface Props {
  children: string;
  shortcodes?: { [key: string]: (props) => JSX.Element };
}

const Markdown: React.FC<Props> = ({ children, shortcodes }) => {
  return (
    <MDXProvider
      components={{
        ul: ({ children }) => {
          return (
            <ul className="list-disc marker:text-indigo-1 marker:border-indigo-1 marker:border-solid marker:border">
              {children}
            </ul>
          );
        },
        li: ({ children }) => {
          return <li className="ml-4 mb-2">{children}</li>;
        },
        ...shortcodes
      }}
    >
      <MDXRenderer>{children}</MDXRenderer>
    </MDXProvider>
  );
};

export default Markdown;
