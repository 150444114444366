import {
  UIButton,
  UIButtonSize,
  UIButtonVariant,
  UIColor,
  UIText,
  UITypography
} from "@perpetua1/oasis-react";
import React from "react";
import { URL } from "../../../constants/destinations";
import Section from "../../../containers/Section";
import { nav } from "../../../utils/navigation";

const AcosSection = ({ content }) => {
  const { nodes } = content;
  const acosCopy = nodes[0];
  const ctaCopy = nodes[1];

  const { title, translation, image } = acosCopy;

  return (
    <Section className="bg-indigo-3-light rounded-tl-small lg:rounded-tr-lg lg:rounded-bl-lg rounded-br-small mt-16 lg:mt-32 py-12 lg:px-12">
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <div className="lg:basis-1/2 flex flex-col items-center md:items-start lg:justify-center gap-4 lg:mt-2 lg:ml-6 lg:mr-9 mb-8">
          <h3 className="text-center text-4xl font-semibold md:text-start">
            {title}
          </h3>
          <UIText
            className="mt-4 lg:mt-8 text-center md:text-start"
            typography={UITypography.body3}
            color={UIColor.gray1}
          >
            {translation?.translation}
          </UIText>
          <UIButton
            className="lg:mt-8"
            variant={UIButtonVariant.primary}
            size={UIButtonSize.large}
            text={ctaCopy?.translation.translation}
            onClick={() => nav(URL.BenchMarker)}
          />
        </div>
        <img
          className="md:max-lg:self-center lg:mt-auto lg:basis-1/2"
          src={image?.url}
          width="650"
        />
      </div>
    </Section>
  );
};

export default AcosSection;
