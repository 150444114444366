import React from "react";
import classNames from "classnames";
import { UIColor, UIText } from "@perpetua1/oasis-react";

import Section from "../../../containers/Section";

const DescriptionSection = ({ content }) => {
  const { nodes } = content;

  return (
    <Section className="flex flex-col md:flex-row md:max-lg:flex-wrap md:max-lg:justify-center items-center lg:items-start lg:gap-32 mt-12 lg:mt-16 md:mb-16 lg:mb-32 space-y-8 md:space-y-0">
      {nodes.map(({ id, image, title, translation }, index) => {
        return (
          <div
            key={id}
            className={classNames("flex flex-col items-center lg:basis-1/3", {
              "md:max-lg:basis-1/2": index !== nodes.length - 1,
              "md:max-lg:!mt-8": index === nodes.length - 1
            })}
          >
            <img className="" src={image?.url} width="112" height="112" />
            <div className={classNames("flex flex-col items-center")}>
              <h4 className="text-center text-xl !font-semibold">{title}</h4>
              <UIText
                className="text-center text-xl md:max-lg:text-base"
                color={UIColor.gray1}
              >
                {translation?.translation}
              </UIText>
            </div>
          </div>
        );
      })}
    </Section>
  );
};

export default DescriptionSection;
