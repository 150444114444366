import React from "react";
import classNames from "classnames";

import { UIColor, UIText } from "@perpetua1/oasis-react";

import Section from "../../../containers/Section";
import Markdown from "../../Markdown";

const PPCSection = ({ content }) => {
  const { nodes } = content;
  if (nodes && nodes.length) {
    return (
      <Section className="flex flex-col lg:gap-40">
        {nodes.map(({ id, image, title, translation }, index) => {
          return (
            <div
              key={id}
              className={classNames("flex flex-col-reverse lg:flex-row", {
                "lg:flex-row-reverse": index % 2 !== 0
              })}
            >
              <img
                className="mt-4 md:max-lg:self-center lg:mt-auto lg:basis-1/2"
                src={image?.url}
                width="500"
              />
              <div
                className={classNames(
                  "flex flex-col lg:basis-1/2 mt-12 lg:mt-2 lg:ml-6",
                  {
                    "lg:ml-16": index % 2 === 0
                  }
                )}
              >
                <UIText className="text-center md:text-start text-3xl md:text-4xl !font-semibold">
                  {title}
                </UIText>
                <UIText
                  className="mt-4 text-center md:text-start text-xl"
                  color={UIColor.gray1}
                >
                  <Markdown>{translation?.childMdx?.body}</Markdown>
                </UIText>
              </div>
            </div>
          );
        })}
      </Section>
    );
  }

  return <></>;
};

export default PPCSection;
