import { DropdownPopoutItem, UIDropdown, UIFlag } from "@perpetua1/oasis-react";
import React, { useState } from "react";
import { Country } from "../../types/countryCodes";

interface FlagPickerProps {
  countryCodes: Country[];
  initialFlag: Country;
  onSelect: React.Dispatch<React.SetStateAction<Country>>;
}

const FlagPicker: React.FC<FlagPickerProps> = ({
  countryCodes,
  initialFlag,
  onSelect: setLocale
}) => {
  const [selectedFlag, setSelectedFlag] = useState<DropdownPopoutItem>({
    title: initialFlag,
    image: (
      <UIFlag className="bg-opacity-0 rounded-none" countryCode={initialFlag} />
    )
  });

  const flags = countryCodes.map(countryCode => ({
    title: countryCode,
    image: (
      <UIFlag className="bg-opacity-0 rounded-none" countryCode={countryCode} />
    )
  }));

  const onClick = (index: number) => {
    setLocale(flags[index].title);
    setSelectedFlag(flags[index]);
  };

  return (
    <UIDropdown
      dropdownTriggerClassName="!py-1 !px-0 mr-3 md:mr-auto"
      selectedItem={selectedFlag}
      items={flags}
      onClick={onClick}
    />
  );
};

export default FlagPicker;
