import React from "react";
import classNames from "classnames";

import Section from "../../../containers/Section";
import Markdown from "../../../components/Markdown";
import { UIText, UITypography } from "@perpetua1/oasis-react";
import Icon, { IconType } from "../../Icon";

{
  /* <Section className="flex flex-col lg:flex-row flex-wrap lg:justify-center lg:gap-12"> */
}

const shortcodes = {
  ul: ({ children }) => {
    return <ul>{children}</ul>;
  },
  li: ({ children }) => {
    return (
      <li className="ml-2 mb-2">
        <Icon
          className="inline-block mr-2"
          iconType={IconType.sonarListItemCheckmark}
        />
        {children}
      </li>
    );
  }
};

const AmazonSection = ({ content }) => {
  const { nodes } = content;

  return (
    <Section className="flex flex-col items-center lg:items-stretch lg:flex-row lg:flex-wrap justify-center gap-y-8 lg:gap-y-0 gap-x-8">
      {nodes.map(({ id, image, title, translation }, index: number) => {
        return (
          <React.Fragment key={id}>
            {index === nodes.length - 1 && (
              <img
                className="hidden lg:inline basis-full lg:max-w-[60%]"
                src={image?.url}
              />
            )}
            <div
              key={id}
              className="lg:basis-1/2 space-y-4 max-w-[580px] border-4 border-dashed last:border-none border-indigo-3-light last:bg-indigo-3-light last:bg-opacity-30 rounded-lg p-4 md:py-7 md:px-6 lg:px-12"
            >
              <h3 className="text-2xl font-semibold">{title}</h3>
              <Markdown shortcodes={shortcodes}>
                {translation?.childMdx.body}
              </Markdown>
            </div>
          </React.Fragment>
        );
      })}
    </Section>
  );
};

export default AmazonSection;
