import React, { useRef, useState } from "react";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import {
  UIButton,
  UIButtonSize,
  UIButtonVariant
} from "@perpetua1/oasis-react";

import Layout from "../containers/Layout";
import Section from "../containers/Section";
import PPCSection from "../components/Sonar/PPCSection";
import HowItWorksSection from "../components/Sonar/HowItWorksSection";
import Headline from "../components/Sonar/Headline";
import DescriptionSection from "../components/Sonar/DescriptionSection";
import AmazonSection from "../components/Sonar/AmazonSection";
import AcosSection from "../components/Sonar/AcosSection";
import SearchSection from "../components/Sonar/SearchSection";
import { Country } from "../types/countryCodes";
import { Language } from "../types/languageCodes";
import {
  downloadKeywords,
  fetchFrequentWordsAndFamiliarProducts,
  searchSonar
} from "../services/sonar";
import {
  FamiliarProduct,
  FrequentWords,
  SEARCH_CATEGORY,
  SonarSearchScore
} from "../types/sonar";
import SEO from "../components/Seo";
import SearchResult from "../components/Sonar/SearchResult";

const Sonar = ({ data }) => {
  const {
    seoSettings,
    searchCategories,
    headline,
    descriptionSection,
    howItWorksSection,
    ppcHeadline,
    ppcSection,
    secondHeadline,
    amazonSection,
    acosSection,
    thirdHeadline,
    footerDescriptionSection,
    backToSonarCTA
  } = data;

  const { metadataTitle, metadataDescription } =
    seoSettings.nodes[0].seoSettings;
  const { language } = useI18next();
  const searchSectionRef = useRef<HTMLDivElement>(null);

  const [selectedLocale, setSelectedLocale] = useState<Country>(
    Country[Language[language]]
  );
  const [selectedCategory, setSelectedCategory] = useState<SEARCH_CATEGORY>(
    SEARCH_CATEGORY[
      searchCategories.nodes[0].translation?.translation?.toLocaleUpperCase()
    ]
  );
  const [input, setInput] = useState("");

  const [searchSize, setSearchSize] = useState<number>(0);
  const [searchResults, setSearchResults] = useState<SonarSearchScore[]>([]);
  const [downloadLink, setDownloadLink] = useState<string>("");
  const [frequentWords, setFrequentWords] = useState<FrequentWords[]>([]);
  const [products, setProducts] = useState<FamiliarProduct[]>([]);
  const [productSize, setProductSize] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showResultsSection, setShowResultsSection] = useState<boolean>(false);

  const searchHandler = async (
    category = selectedCategory,
    keyword = input
  ) => {
    setShowResultsSection(true);

    try {
      setDownloadLink("");

      const {
        data: { listWithScore, size }
      } = await searchSonar({
        country: selectedLocale,
        keyword,
        category
      });

      if (listWithScore) {
        const filteredScores = listWithScore
          .filter(score => score.opened === true)
          .slice(0, 16);
        setSearchResults(filteredScores);
      }

      setSearchSize(size);
      setIsLoading(true);

      const [
        {
          data: { listWithCount: frequentWords }
        },
        {
          data: { products, size: productsSize }
        }
      ] = await fetchFrequentWordsAndFamiliarProducts({
        country: selectedLocale,
        keyword,
        category
      });

      setIsLoading(false);

      if (frequentWords) {
        setFrequentWords(frequentWords);
      }

      setProducts(products);
      setProductSize(productsSize);

      const { data: downloadResource } = await downloadKeywords({
        country: selectedLocale,
        keyword,
        category
      });

      setDownloadLink("https://sonar.sellics.com/download/" + downloadResource);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const onClickSearch = (category: SEARCH_CATEGORY, input: string) => {
    setInput(input);
    searchHandler(category, input);
  };

  return (
    <Layout
      theme="indigo"
      primaryButtonId="nav-getstarted"
      secondaryButtonId="nav-signin"
      locale={language}
    >
      <SEO title={metadataTitle} description={metadataDescription} />
      <SearchSection
        ref={searchSectionRef}
        categories={searchCategories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        keyword={input}
        setKeyword={setInput}
        locales={[Country.UnitedStates, Country.Germany]}
        selectedLocale={selectedLocale}
        setSelectedLocale={setSelectedLocale}
        searchCtaCopy={"Search"}
        onSubmit={searchHandler}
      />
      {showResultsSection && (
        <SearchResult
          results={searchResults}
          downloadLink={downloadLink}
          isLoading={isLoading}
          frequentWords={frequentWords}
          onClickSearch={onClickSearch}
          productSize={productSize}
          products={products}
          searchSize={searchSize}
        />
      )}
      <Headline content={headline} variant="h1" />
      <DescriptionSection content={descriptionSection} />
      <HowItWorksSection content={howItWorksSection} />
      <Headline content={secondHeadline} variant="h2" />
      <AmazonSection content={amazonSection} />
      <Headline content={ppcHeadline} variant="h2" />
      <PPCSection content={ppcSection} />
      <AcosSection content={acosSection} />
      <Headline content={thirdHeadline} variant="h2" />
      <DescriptionSection content={footerDescriptionSection} />
      <Section className="flex justify-center">
        <UIButton
          className="mt-12 lg:mt-0"
          variant={UIButtonVariant.primary}
          size={UIButtonSize.large}
          text={backToSonarCTA.nodes[0].translation?.translation}
          onClick={() => {
            searchSectionRef.current?.scrollIntoView({ behavior: "smooth" });
          }}
        />
      </Section>
    </Layout>
  );
};

export default Sonar;

export const fragment = graphql`
  fragment ImageAndTextDescription on ContentfulMarketingWebTranslationsConnection {
    nodes {
      id
      key
      title
      translation {
        translation
        childMdx {
          body
        }
      }
      image {
        url
      }
    }
  }
`;

export const query = graphql`
  query ($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    seoSettings: allContentfulMarketingWebTranslations(
      filter: {
        key: { eq: "sonar.seo_settings" }
        node_locale: { eq: $language }
      }
    ) {
      nodes {
        id
        key
        node_locale
        title
        seoSettings {
          metadataTitle
          metadataDescription
        }
      }
    }

    searchCategories: allContentfulMarketingWebTranslations(
      filter: {
        key: {
          in: [
            "sonar.search_category_keyword"
            "sonar.search_category_extended"
            "sonar.search_category_asin"
            "sonar.search_category_translate"
          ]
        }
        node_locale: { eq: $language }
      }
      sort: { fields: createdAt, order: ASC }
    ) {
      nodes {
        id
        title
        translation {
          translation
        }
      }
    }

    headline: allContentfulMarketingWebTranslations(
      filter: {
        key: { in: ["sonar.headline"] }
        node_locale: { eq: $language }
      }
    ) {
      ...ImageAndTextDescription
    }

    descriptionSection: allContentfulMarketingWebTranslations(
      filter: {
        key: {
          in: [
            "sonar.millions_of_keywords"
            "sonar.asin_lookup"
            "sonar.easy_to_start"
          ]
        }
        node_locale: { eq: $language }
      }
      sort: { fields: createdAt, order: ASC }
    ) {
      ...ImageAndTextDescription
    }

    howItWorksSection: allContentfulMarketingWebTranslations(
      filter: {
        key: {
          in: [
            "sonar.how_does_it_work"
            "sonar.how_sonar_calculates"
            "sonar.which_marketplaces_covered"
          ]
        }
        node_locale: { eq: $language }
      }
      sort: { fields: createdAt, order: ASC }
    ) {
      ...ImageAndTextDescription
    }

    secondHeadline: allContentfulMarketingWebTranslations(
      filter: {
        key: { in: ["sonar.second_headline"] }
        node_locale: { eq: $language }
      }
    ) {
      ...ImageAndTextDescription
    }

    amazonSection: allContentfulMarketingWebTranslations(
      filter: {
        key: {
          in: [
            "sonar.amazon_a9_algorithm"
            "sonar.amazon_ranking_factors"
            "sonar.amazon_listing_optimization"
          ]
        }
        node_locale: { eq: $language }
      }
      sort: { fields: createdAt, order: ASC }
    ) {
      ...ImageAndTextDescription
    }

    ppcHeadline: allContentfulMarketingWebTranslations(
      filter: {
        key: { in: ["sonar.ppc_headline"] }
        node_locale: { eq: $language }
      }
    ) {
      ...ImageAndTextDescription
    }

    ppcSection: allContentfulMarketingWebTranslations(
      filter: {
        key: {
          in: [
            "sonar.ppc_how_does_it_work"
            "sonar.keyword_match_type"
            "sonar.solution"
          ]
        }
        node_locale: { eq: $language }
      }
      sort: { fields: id, order: DESC }
    ) {
      ...ImageAndTextDescription
    }

    acosSection: allContentfulMarketingWebTranslations(
      filter: {
        key: { in: ["sonar.acos_copy", "sonar.analyze_account_cta"] }
        node_locale: { eq: $language }
      }
      sort: { fields: createdAt, order: ASC }
    ) {
      ...ImageAndTextDescription
    }

    thirdHeadline: allContentfulMarketingWebTranslations(
      filter: {
        key: { in: ["sonar.third_headline"] }
        node_locale: { eq: $language }
      }
      sort: { fields: createdAt, order: ASC }
    ) {
      ...ImageAndTextDescription
    }

    footerDescriptionSection: allContentfulMarketingWebTranslations(
      filter: {
        key: {
          in: [
            "sonar.search_volume"
            "sonar.asin_lookup_footer"
            "sonar.keyword_translator"
          ]
        }
        node_locale: { eq: $language }
      }
      sort: { fields: createdAt, order: ASC }
    ) {
      ...ImageAndTextDescription
    }

    backToSonarCTA: allContentfulMarketingWebTranslations(
      filter: {
        key: { in: ["sonar.back_to_sonar_cta"] }
        node_locale: { eq: $language }
      }
    ) {
      ...ImageAndTextDescription
    }
  }
`;
