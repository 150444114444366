import React, { useState } from "react";
import {
  UIButton,
  UIButtonSize,
  UIButtonVariant,
  UIColor,
  UILoadingIndicator,
  UIPill,
  UIText,
  UITypography
} from "@perpetua1/oasis-react";
import Section from "../../../containers/Section";
import {
  FrequentWords,
  SEARCH_CATEGORY,
  SonarSearchScore
} from "../../../types/sonar";
import Rating from "../Rating";
import { nav } from "../../../utils/navigation";
import { URL } from "../../../constants/destinations";
import classNames from "classnames";

interface SearchResultProps {
  downloadLink: string;
  isLoading: boolean;
  searchSize: number;
  frequentWords: FrequentWords[];
  onClickSearch: (category: SEARCH_CATEGORY, title: string) => void;
  products: any[];
  productSize: number;
  results: SonarSearchScore[];
}

const SearchResult: React.FC<SearchResultProps> = ({
  downloadLink,
  isLoading,
  onClickSearch,
  results,
  searchSize,
  frequentWords,
  products,
  productSize
}) => {
  return (
    <>
      <Section className="relative">
        <div className="lg:grid lg:grid-cols-2 lg:grid-rows-[repeat(auto-fit,minmax(0,fit-content))] lg:gap-y-8 lg:gap-x-12 py-16 lg:pb-8 px-8 md:px-12 lg:px-16 lg:-mt-[360px] rounded-2xl bg-white">
          <div className="col-start-1 col-end-2 row-span-2 space-y-2">
            <div className="flex items-center">
              <h2 className="mr-auto text-3xl font-semibold">Keywords</h2>
              <UIButton
                disabled={!downloadLink}
                text={
                  <div className="flex items-center">
                    <a href={downloadLink} target="_blank">
                      Download
                    </a>
                    {isLoading && !downloadLink && (
                      <UILoadingIndicator className="ml-2 [&>svg]:fill-indigo-1" />
                    )}
                  </div>
                }
                variant={UIButtonVariant.secondary}
              />
            </div>
            <div>
              <div className="flex py-2 px-2  border-b border-gray-4-light">
                <UIText className="mr-auto" color={UIColor.gray1}>
                  {searchSize} found
                </UIText>
                <UIText color={UIColor.gray1}>Search Volume</UIText>
              </div>
              <ul>
                {results.slice(0, 10).map(({ title, rating }) =>
                  rating < 1 ? (
                    <></>
                  ) : (
                    <li
                      key={title}
                      className="flex items-center py-4 px-2 border-b border-b-gray-4-light hover:bg-gray-6-light cursor-pointer"
                      onClick={() =>
                        onClickSearch(SEARCH_CATEGORY.KEYWORD, title)
                      }
                    >
                      <UIText
                        className="mr-auto"
                        typography={UITypography.label2}
                      >
                        {title}
                      </UIText>
                      <Rating rating={rating} />
                    </li>
                  )
                )}
              </ul>
              {searchSize > 0 && (
                <>
                  <div className="flex flex-col items-center gap-4 border-b border-b-gray-4-light py-4 lg:px-8 bg-indigo-3-light bg-opacity-20">
                    <UIText
                      className="text-center"
                      typography={UITypography.body2}
                    >
                      Are you doing keyword research mostly for PPC or for SEO?
                    </UIText>
                    <UIText
                      className="text-center"
                      typography={UITypography.body}
                    >
                      Optimize your product listings on Amazon and find out how
                      your main KPIs compare to others in the category you are
                      selling in with Perpetua’s Benchmarker tool.
                    </UIText>
                    <UIButton
                      className="text-indigo-1 !border-indigo-1"
                      variant={UIButtonVariant.secondary}
                      text={"Get Your Free Benchmark"}
                      onClick={() => nav(URL.BenchMarker)}
                    />
                  </div>
                  <ul>
                    {[...Array(5)].map(n => (
                      <li
                        key={n}
                        className="blur flex py-4 px-2 border-b border-b-gray-4-light"
                      >
                        <span className="mr-auto text-base font-semibold">
                          #### ### ###
                        </span>
                        <Rating rating={2} />
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
          <div className="mt-8 lg:mt-0 lg:col-start-2 lg:row-start-1 lg:row-end-2 space-y-4">
            <h2 className="mb-4 text-3xl font-semibold">Frequent Words</h2>
            {isLoading ? (
              <div className="flex justify-center">
                <UILoadingIndicator className="[&>svg]:fill-indigo-1 !w-12 !h-12" />
              </div>
            ) : (
              <div className="flex flex-wrap gap-1 gap-y-2 lg:gap-2 lg:gap-y-3">
                {frequentWords.length > 0 &&
                  frequentWords.map(({ title, count }) => (
                    <UIPill
                      key={title}
                      containerClassName={classNames("lg:!py-2 lg:!px-4", {
                        "!bg-indigo-2-light": count >= 1,
                        "!bg-indigo-3-light": count >= 0.5 && count < 1,
                        "!bg-purple-3-light": count < 0.5
                      })}
                      textClassName="!text-base !font-semibold !text-gray-3-dark"
                      text={title}
                      onClick={() =>
                        onClickSearch(SEARCH_CATEGORY.KEYWORD, title)
                      }
                    />
                  ))}
              </div>
            )}
          </div>
          <div className="mt-8 lg:mt-0 lg:col-start-2 lg:row-start-2 space-y-4">
            <h2 className="text-3xl font-semibold">Relevant Products</h2>
            {isLoading ? (
              <div className="flex justify-center">
                <UILoadingIndicator className="[&>svg]:fill-indigo-1 !w-12 !h-12" />
              </div>
            ) : (
              <React.Fragment>
                <UIText color={UIColor.gray1}>{productSize} found</UIText>
                <div className="flex flex-wrap items-center gap-8 cursor-pointer">
                  {products.length > 0 &&
                    products.map(({ asin, imgId }) => (
                      <div
                        key={asin}
                        onClick={() =>
                          onClickSearch(SEARCH_CATEGORY.ASIN, asin)
                        }
                      >
                        <img
                          width={"52"}
                          src={`http://ecx.images-amazon.com/images/I/${imgId}._AC_SX77_SY77_QL70_.jpg`}
                        />
                      </div>
                    ))}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Section>
      <hr className="w-48 h-1 mx-auto my-4 lg:mt-16 bg-gradient-to-r from-indigo-1 to-blue-1 border-0 rounded" />
    </>
  );
};

export default SearchResult;
