import axios from "axios";

import {
  RequestBodyProps,
  SEARCH_CATEGORY,
  SonarFamiliarProductsResult,
  SonarFrequentWordsResult,
  SonarSearchResult,
  SORT_BY
} from "./../../types/sonar";

const BASE_URL = "https://sonar.sellics.com";

export const searchSonar = ({
  keyword,
  country,
  sortBy = SORT_BY.R,
  category = SEARCH_CATEGORY.KEYWORD
}: RequestBodyProps) => {
  return axios.post<SonarSearchResult>(`${BASE_URL}/search`, {
    searchMarket: country,
    sortBy,
    [category]: keyword,
    ...(category === SEARCH_CATEGORY.TRANSLATE && {
      keyword,
      lang: country
    })
  });
};

export const downloadKeywords = ({
  keyword,
  country,
  category = SEARCH_CATEGORY.KEYWORD,
  sortBy = SORT_BY.R
}: RequestBodyProps) => {
  return axios.post<string>(`https://sonar.sellics.com/download`, {
    country,
    sortBy,
    [category]: keyword
  });
};

export const fetchFrequentWords = ({
  country,
  keyword,
  category = SEARCH_CATEGORY.KEYWORD
}: RequestBodyProps) => {
  return axios.post<SonarFrequentWordsResult>(`${BASE_URL}/count`, {
    country,
    [category]: keyword,
    ...(category === SEARCH_CATEGORY.TRANSLATE && {
      keyword,
      lang: country
    })
  });
};

export const fetchFamilarProducts = ({
  country,
  keyword,
  category = SEARCH_CATEGORY.KEYWORD
}: RequestBodyProps) => {
  return axios.post<SonarFamiliarProductsResult>(`${BASE_URL}/products`, {
    country,
    [category]: keyword,
    ...(category === SEARCH_CATEGORY.TRANSLATE && {
      keyword,
      lang: country
    })
  });
};

export const fetchFrequentWordsAndFamiliarProducts = ({
  country,
  keyword,
  category = SEARCH_CATEGORY.KEYWORD
}: RequestBodyProps) => {
  const frequentWords = fetchFrequentWords({ country, keyword, category });
  const familiarProducts = fetchFamilarProducts({ country, keyword, category });

  return Promise.all([frequentWords, familiarProducts]);
};
