import { Country } from "./countryCodes";

export interface SonarSearchResult {
  size: number;
  listWithScore: SonarSearchScore[];
}

export interface SonarFrequentWordsResult {
  size: number;
  listWithCount: FrequentWords[];
}

export interface SonarFamiliarProductsResult {
  size: number;
  products: FamiliarProduct[];
}

export interface SonarSearchScore {
  title: string;
  rating: number;
  opened: boolean;
}

export interface FrequentWords {
  title: string;
  count: number;
}

export interface FamiliarProduct {
  title: string;
  asin: string;
  imgId: string;
}

export enum SEARCH_CATEGORY {
  KEYWORD = "keyword",
  EXTENDED = "ext",
  ASIN = "asin",
  TRANSLATE = "translate"
}

export enum SORT_BY {
  R = "R",
  SEARCH_VOLUME = "SV"
}

export type RequestBodyProps = {
  keyword: string;
  country: Country;
  sortBy?: SORT_BY;
  category?: SEARCH_CATEGORY;
};

type SearchRequestBodyProps = RequestBodyProps;
type DownloadRequestBodyProps = Omit<RequestBodyProps, "sortBy">;

export type SearchCategories = {
  nodes: { id: string; translation: { translation: string } }[];
};
