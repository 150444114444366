import React from "react";
import classNames from "classnames";

interface RatingProps {
  rating: number;
}

const Rating: React.FC<RatingProps> = ({ rating }) => {
  if (rating === 0) return null;

  return (
    <div className="flex gap-[2px]">
      {[...Array(rating)].map(r => (
        <span
          className={classNames(
            "w-1 h-5 rounded-sm",
            { "bg-indigo-3-dark": rating === 5 },
            { "bg-indigo-2": rating === 4 },
            { "bg-indigo-1": rating === 3 },
            { "bg-indigo-2-light": rating === 2 || rating === 1 }
          )}
        ></span>
      ))}
    </div>
  );
};

export default Rating;
