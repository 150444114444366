import React, { forwardRef } from "react";

import {
  UIButton,
  UIButtonVariant,
  UIColor,
  UIInput,
  UIText,
  UITypography
} from "@perpetua1/oasis-react";

import Section from "../../../containers/Section";
import FlagPicker from "../../FlagPicker";

import { SearchCategories, SEARCH_CATEGORY } from "../../../types/sonar";
import { Country } from "../../../types/countryCodes";
import Video from "../../../assets/sonar-page/sonar-background.mp4";


interface SearchSectionProps {
  categories: SearchCategories;
  searchCtaCopy: string;
  selectedCategory: SEARCH_CATEGORY;
  setSelectedCategory: React.Dispatch<React.SetStateAction<SEARCH_CATEGORY>>;
  onSubmit: (category?: any, keyword?: string) => Promise<void>;
  locales: Country[];
  selectedLocale: Country;
  setSelectedLocale: React.Dispatch<React.SetStateAction<Country>>;
  keyword: string;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
}

type DivRef = HTMLDivElement;

const SearchSection: React.ForwardRefExoticComponent<
  SearchSectionProps & React.RefAttributes<HTMLDivElement>
> = forwardRef<DivRef, SearchSectionProps>(
  (
    {
      categories,
      searchCtaCopy,
      selectedCategory,
      setSelectedCategory,
      onSubmit,
      locales,
      selectedLocale,
      setSelectedLocale,
      keyword,
      setKeyword
    },
    ref
  ) => {
    const searchHandler = (e: React.FormEvent) => {
      e.preventDefault();
      onSubmit();
    };

    return (
      <Section className="relative flex flex-col justify-center items-center lg:max-w-full h-screen w-screen">
        <video
          className="absolute w-full h-full object-cover"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={Video} type="video/mp4" />
        </video>
        <div className="-translate-y-10 mb-8 z-10" ref={ref}>
          <UIText
            className="text-center"
            typography={UITypography.header6}
            color={UIColor.white}
          >
            Sonar
          </UIText>
        </div>
        <form className="relative -translate-y-10" onSubmit={searchHandler}>
          <ul className="flex justify-center mb-4">
            {categories.nodes.map(
              ({ id, translation: { translation: category } }) => (
                <li
                  key={id}
                  className="mr-4 lg:mr-10 cursor-pointer"
                  onClick={() =>
                    setSelectedCategory(
                      SEARCH_CATEGORY[category.toLocaleUpperCase()]
                    )
                  }
                >
                  <UIText
                    className={"font-normal"}
                    typography={UITypography.label3}
                    color={
                      SEARCH_CATEGORY[category.toLocaleUpperCase()] ===
                      selectedCategory
                        ? UIColor.white
                        : UIColor.grayLight3
                    }
                  >
                    {category}
                  </UIText>
                </li>
              )
            )}
          </ul>
          <div className="flex flex-wrap md:flex-nowrap items-center md:space-x-4">
            <FlagPicker
              countryCodes={locales}
              initialFlag={selectedLocale}
              onSelect={setSelectedLocale}
            />
            <UIInput
              containerClassName="basis-3/4 md:basis-auto"
              className="lg:w-96 focus:ring-purple-2"
              placeholder="Search keywords"
              value={keyword}
              onChange={e => setKeyword(e.target.value)}
            />
            <UIButton
              className="basis-full md:basis-auto mt-2 md:mt-0"
              variant={UIButtonVariant.secondary}
              text={searchCtaCopy || "Search"}
              type="submit"
            />
          </div>
        </form>
      </Section>
    );
  }
);

export default SearchSection;
