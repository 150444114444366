import React from "react";
import { UIText, UIColor, UITypography } from "@perpetua1/oasis-react";

import Section from "../../../containers/Section";

const Headline = ({ content, variant }) => {
  const { nodes } = content;
  const { title, translation } = nodes[0];

  const Heading = variant;

  return (
    <Section className="my-16 text-center">
      <Heading className="text-4xl md:text-5xl font-semibold md:font-bold">
        {title}
      </Heading>
      <UIText
        className="mt-8"
        typography={UITypography.body3}
        color={UIColor.gray1}
      >
        {translation?.translation}
      </UIText>
    </Section>
  );
};

export default Headline;
